import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Row, Col } from 'antd';
import DefaultLayout from '../components/DefaultLayout';
import './MonthlyReports.css';

const { Option } = Select;

const MonthlyReports = () => {
    const [salesData, setSalesData] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const backendApiUrl = process.env.REACT_APP_BACKEND_API;

    useEffect(() => {
        const fetchSalesReport = async () => {
            try {
                const { data } = await axios.get(`${backendApiUrl}/bills/sales-report?year=${year}&month=${month}`);
                const totalRow = data.reduce((acc, curr) => {
                    acc.totalSales += curr.totalSales;
                    acc.totalTax += curr.totalTax || 0;
                    acc.totalDiscount += curr.totalDiscount;
                    acc.totalPurchasePrice += curr.totalPurchasePrice;
                    acc.totalProfit += curr.totalProfit;
                    return acc;
                }, { totalSales: 0, totalTax: 0, totalDiscount: 0, totalPurchasePrice: 0, totalProfit: 0, day: 'Total' });
                setSalesData([...data, totalRow]);
            } catch (error) {
                console.error("Error fetching sales report:", error);
                setSalesData([]);
            }
        };

        fetchSalesReport();
    }, [month, year, backendApiUrl]);

    const months = Array.from({ length: 12 }, (v, i) => ({
        name: new Date(0, i).toLocaleString('default', { month: 'long' }),
        value: i + 1
    }));

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2019 }, (v, i) => 2024 + i);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'day',
            key: 'day',
            render: (text, record) => text === 'Total' ? 'Total' : `${year}-${month.toString().padStart(2, '0')}-${text.toString().padStart(2, '0')}`
        },
        {
            title: 'Gross Sales',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Discounts',
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Purchase Price',
            dataIndex: 'totalPurchasePrice',
            key: 'totalPurchasePrice',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            render: text => `Rs. ${text.toFixed(2)}`
        }
    ];

    return (
        <DefaultLayout>
            <div className="report-container">
                <h2>Sales Report for {months[month - 1].name} {year}</h2>
                <Row gutter={16} justify="start">
                    <Col xs={24} sm={12} md={6}>
                        <Select defaultValue={month} style={{ width: 200 }} onChange={setMonth}>
                            {months.map(m => (
                                <Option key={m.value} value={m.value}>{m.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Select defaultValue={year} style={{ width: 200 }} onChange={setYear}>
                            {years.map(y => (
                                <Option key={y} value={y}>{y}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={salesData} pagination={false} bordered summary={pageData => {
                    let totalSales = 0, totalTax = 0, totalDiscount = 0, totalPurchasePrice = 0, totalProfit = 0;
                    pageData.forEach(({ totalSales: sales, totalTax: tax, totalDiscount: discount, totalPurchasePrice: purchasePrice, totalProfit: profit }) => {
                      totalSales += sales;
                      totalTax += tax;
                      totalDiscount += discount;
                      totalPurchasePrice += purchasePrice;
                      totalProfit += profit;
                    });
                  }} />
            </div>
        </DefaultLayout>
    );
};

export default MonthlyReports;
