import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import axios from 'axios';
import DefaultLayout from '../components/DefaultLayout';

const DailyPriceUpdatePage = () => {
  const [categories, setCategories] = useState([]);
  const [packings] = useState(['tinlet', 'quarter', 'gallon', 'drum', '9.60 litre', '12.80 liter', '20 liter', '5 kg', 'dozen', 'ea']);
  const [form] = Form.useForm();
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;

  const hardcodedCategories = [
    'All Weather', 'Plastic Emulsion', 'Super Emulsion', 'Synthetic Enamel', 'Stain Free', 'Velvet', 'Sanitary'
  ];

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(`${backendApiUrl}/categories/get-categories`);
        setCategories([...hardcodedCategories, ...data.map(category => category.name)]);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        message.error('Failed to fetch categories');
      }
    };
    getCategories();
  }, []);

  const handleSubmit = async (values) => {
    try {
      await axios.post(`${backendApiUrl}/items/update-prices-by-category`, values);
      message.success('Prices updated successfully for selected packing type');
      form.resetFields();
    } catch (error) {
      console.error('Failed to update prices:', error);
      message.error('Failed to update prices');
    }
  };

  return (
    <DefaultLayout>
      <h1>Update Price of Items</h1>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select a category' }]}>
          <Select>
            {categories.map(category => (
              <Select.Option key={category} value={category}>{category}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="packing" label="Packing" rules={[{ required: true, message: 'Please select a packing type' }]}>
          <Select>
            {packings.map(packing => (
              <Select.Option key={packing} value={packing}>{packing}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="newPrice" label="New Price" rules={[{ required: true, message: 'Please enter the new price' }]}>
          <Input type="number" prefix="Rs. " />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Prices
          </Button>
        </Form.Item>
      </Form>
    </DefaultLayout>
  );
};

export default DailyPriceUpdatePage;