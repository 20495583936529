import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import DefaultLayout from "../components/DefaultLayout";
import ImageUpload from "../components/ImageUpload";

const { confirm } = Modal;

const ItemPage = () => {
  const dispatch = useDispatch();
  const [itemsData, setItemsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;
  const [searchText, setSearchText] = useState("");
  const [form] = Form.useForm();

  const hardcodedCategories = [
    { name: "All Weather", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255623/All-Weather-3_540x_gdibhz.png" },
    { name: "Plastic Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255568/Plastic-Emulsion-2_ab5dfa.png" },
    { name: "Super Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255658/Brighto-Super-Emulsion_2048x2048_ppzyfd.png" },
    { name: "Synthetic Enamel", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255707/Synthatic-Enamel-2_nxyi4i.png" },
    { name: "Stain Free", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255739/Stain-Free-2_bzkwzg.png" },
    { name: "Velvet", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255786/velvet-gallon_j28nqz.png" },
    { name: "Sanitary", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713256974/Sanitary_jpt9aa.jpg" },
  ];

  useEffect(() => {
    getAllItems();
    getCategories();
  }, []);

  useEffect(() => {
    if (editItem) {
      form.setFieldsValue({
        ...editItem,
        packingOptions: editItem.packingOptions ? editItem.packingOptions : [],
      });
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const getAllItems = async () => {
    try {
      dispatch({ type: "SHOW_LOADING" });
      const { data } = await axios.get(`${backendApiUrl}/items/get-item`);
      setItemsData(data);
      dispatch({ type: "HIDE_LOADING" });
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      message.error("Failed to fetch items");
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await axios.get(`${backendApiUrl}/categories/get-categories`);
      setCategories([...hardcodedCategories, ...data]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = itemsData.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure delete this item?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (record) => {
    try {
      dispatch({ type: "SHOW_LOADING" });
      await axios.post(`${backendApiUrl}/items/delete-item`, {
        itemId: record._id,
      });
      message.success("Item deleted successfully");
      getAllItems();
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      message.error("Failed to delete item");
    }
  };

  const handleSubmit = async (values) => {
    const apiEndpoint = editItem
      ? `${backendApiUrl}/items/edit-item`
      : `${backendApiUrl}/items/add-item`;
    const method = editItem ? "PUT" : "POST";

    try {
      dispatch({ type: "SHOW_LOADING" });
      await axios({
        method,
        url: apiEndpoint,
        data: editItem ? { ...values, itemId: editItem._id } : values,
      });
      message.success(`Item ${editItem ? "updated" : "added"} successfully`);
      form.resetFields();
      getAllItems();
      setPopupModal(false);
      setEditItem(null);
    } catch (error) {
      console.error("Error processing item:", error);
      message.error("Failed to process the item");
    } finally {
      dispatch({ type: "HIDE_LOADING" });
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name" },
    { title: "Category", dataIndex: "category" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img
          src={image || "./defaultProductImage.jpg"}
          alt={record.name}
          height="60"
          width="60"
        />
      ),
    },
    {
      title: "Packing Options",
      dataIndex: "packingOptions",
      render: (packingOptions) => (
        <div>
          {packingOptions.map((opt) => (
            <div key={opt._id}>
              {opt.packing}: Rs. {opt.price}, Stock: {opt.stock}, Purchase Price: Rs. {opt.purchasePrice}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EditOutlined
            onClick={() => {
              setEditItem(record);
              setPopupModal(true);
            }}
            style={{ cursor: "pointer", marginRight: 12 }}
          />
          <DeleteOutlined
            onClick={() => showDeleteConfirm(record)}
            style={{ cursor: "pointer", color: "red" }}
          />
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Item List</h1>
        <Button
          type="primary"
          onClick={() => {
            setEditItem(null);
            form.resetFields();
            setPopupModal(true);
          }}
        >
          Add Item
        </Button>
      </div>
      <Input
        placeholder="Search items by name"
        value={searchText}
        onChange={handleSearch}
        style={{ width: 300, marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={filteredData}
        bordered
        scroll={{ x: "max-content" }}
      />

      {popupModal && (
        <Modal
          title={`${editItem ? "Edit Item" : "Add New Item"}`}
          open={popupModal}
          onCancel={() => {
            setEditItem(null);
            setPopupModal(false);
          }}
          footer={false}
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={
              editItem
                ? { ...editItem, packingOptions: editItem.packingOptions }
                : { packingOptions: [] }
            }
            onFinish={handleSubmit}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter a name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="image" label="Image URL">
              <Input placeholder="Image URL will appear here after upload" />
            </Form.Item>
            <ImageUpload
              onImageUpload={(url) => form.setFieldsValue({ image: url })}
            />

            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "Please select a category" }]}
              >
                <Select>
                  {categories.map((category) => (
                    <Select.Option key={category.name} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.List name="packingOptions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "packing"]}
                          fieldKey={[fieldKey, "packing"]}
                          rules={[
                            { required: true, message: "Missing packing type" },
                          ]}
                          label="Packing"
                        >
                          <Select style={{ width: 100 }}>
                            <Select.Option value="ea">EA</Select.Option>
                            <Select.Option value="tinlet">Tinlet</Select.Option>
                            <Select.Option value="quarter">Quarter</Select.Option>
                            <Select.Option value="gallon">Gallon</Select.Option>
                            <Select.Option value="drum">Drum</Select.Option>
                            <Select.Option value="9.60 litre">
                              9.60 Litre
                            </Select.Option>
                            <Select.Option value="12.80 liter">
                            12.80 Liter
                          </Select.Option>
                          <Select.Option value="20 liter">
                            20 Liter/KG
                          </Select.Option>
                          <Select.Option value="5 kg">5 KG</Select.Option>
                          <Select.Option value="dozen">Dozen</Select.Option>
                          <Select.Option value="kg">KG</Select.Option>
                          <Select.Option value="liter">Liter</Select.Option>
                          <Select.Option value="loose">Loose</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "price"]}
                        fieldKey={[fieldKey, "price"]}
                        rules={[{ required: true, message: "Missing price" }]}
                        label="Price"
                      >
                        <Input
                          style={{ width: 100, marginLeft: 8 }}
                          placeholder="Price"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "purchasePrice"]}
                        fieldKey={[fieldKey, "purchasePrice"]}
                        rules={[
                          { required: true, message: "Missing purchase price" },
                        ]}
                        label="Purchase Price"
                      >
                        <Input
                          style={{ width: 100, marginLeft: 8 }}
                          placeholder="Purchase Price"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "stock"]}
                        fieldKey={[fieldKey, "stock"]}
                        rules={[
                          { required: true, message: "Missing stock quantity" },
                        ]}
                        label="Stock"
                      >
                        <Input
                          style={{ width: 100, marginLeft: 8 }}
                          placeholder="Stock (e.g. 50+10)"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    style={{ marginTop: 8 }}
                  >
                    Add Price/Stock
                  </Button>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginTop: 8 }}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </DefaultLayout>
  );
};

export default ItemPage;
