import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Row, Col } from 'antd';
import DefaultLayout from '../components/DefaultLayout';

const DailyReports = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const backendApiUrl = process.env.REACT_APP_BACKEND_API;

    const formatDate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const fetchReport = () => {
        setLoading(true);
        const formattedDate = formatDate(date);
        
        axios.get(`${backendApiUrl}/bills/daily-report`, {
            params: {
                start: formattedDate,
                end: formattedDate,
                page: pagination.current,
                pageSize: pagination.pageSize,
            },
        }).then(res => {
            setLoading(false);
            if (res.data && res.data.length === 0) { 
                setData([]);
                setPagination(p => ({ ...p, total: 0 }));
            } else {
                setData(res.data);
                setPagination(p => ({
                    ...p,
                    total: res.data.totalCount,
                }));
            }
        }).catch(err => {
            setLoading(false);
            console.error("Error fetching data:", err);
            setData([]);
        });
    };

    useEffect(() => {
        fetchReport();
    }, [date, pagination.current, pagination.pageSize]);

    const handleTableChange = (newPagination) => {
        setPagination(newPagination);
    };

    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setDate(newDate);
    };

    const columns = [
        { title: 'Date', dataIndex: 'date', key: 'date', render: () => formatDate(date) },
        {
            title: 'Total Sales',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Discount',
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Purchase Price',
            dataIndex: 'totalPurchasePrice',
            key: 'totalPurchasePrice',
            render: text => `Rs. ${text.toFixed(2)}`
        },
        {
            title: 'Profit',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            render: text => `Rs. ${text.toFixed(2)}`
        }
    ];

    // Customize no data message
    const customLocale = {
        emptyText: `No data found for ${formatDate(date)}`,
    };

    return (
        <DefaultLayout>
            <div className="report-container">
                <h2>Select a Date</h2>
                <Row gutter={16} justify="start">
                    <Col>
                        <input type="date" value={date.toISOString().split('T')[0]} onChange={handleDateChange} />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    rowKey={record => record._id}
                    bordered
                    locale={customLocale}
                />
            </div>
        </DefaultLayout>
    );
};

export default DailyReports;
