import { Table, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DefaultLayout from "../components/DefaultLayout";
import "./CustomerPage.css";

const CustomerPage = () => {
  const [billsData, setBillsData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;

  const getAllBills = async () => {
    try {
      dispatch({ type: "SHOW_LOADING" });
      const { data } = await axios.get(`${backendApiUrl}/bills/get-bills`);
      const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      aggregateData(sortedData);
      dispatch({ type: "HIDE_LOADING" });
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      console.error(error);
    }
  };

  const aggregateData = (data) => {
    const aggregatedData = data.map(bill => ({
      key: bill._id,
      customerName: bill.customerName,
      customerNumber: bill.customerNumber,
      transactionID: bill._id,
      date: bill.date,
      totalAmount: bill.totalAmount,
    }));
    setBillsData(aggregatedData);
  };

  useEffect(() => {
    getAllBills();
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = billsData.filter(
    item => item.customerName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.customerNumber.includes(searchText)
  );

  const columns = [
    { title: "Customer Name", dataIndex: "customerName" },
    { title: "Contact No", dataIndex: "customerNumber" },
    { title: "Transaction ID", dataIndex: "transactionID" },
    { title: "Date", dataIndex: "date", render: date => date.split('T')[0] },
    { title: "Amount", dataIndex: "totalAmount" }
  ];

  return (
    <DefaultLayout>
      <h1>Customers Page</h1>
      <Input
        placeholder="Search by name or contact no"
        value={searchText}
        onChange={handleSearch}
        style={{ width: 300, marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={filteredData}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </DefaultLayout>
  );
};

export default CustomerPage;