import React, { useEffect, useState } from "react";
import { Col, Row, Input, Button, Modal, Form, message } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import ItemList from "../components/ItemList";
import DefaultLayout from "../components/DefaultLayout";
import ImageUpload from "../components/ImageUpload";

const Homepage = () => {
  const [itemsData, setItemsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Weather");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", imageUrl: "" });
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;

  const hardcodedCategories = [
    { name: "All Weather", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255623/All-Weather-3_540x_gdibhz.png" },
    { name: "Plastic Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255568/Plastic-Emulsion-2_ab5dfa.png" },
    { name: "Super Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255658/Brighto-Super-Emulsion_2048x2048_ppzyfd.png" },
    { name: "Synthetic Enamel", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255707/Synthatic-Enamel-2_nxyi4i.png" },
    { name: "Stain Free", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255739/Stain-Free-2_bzkwzg.png" },
    { name: "Velvet", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255786/velvet-gallon_j28nqz.png" },
    { name: "Sanitary", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713256974/Sanitary_jpt9aa.jpg" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllItems = async () => {
      dispatch({ type: "SHOW_LOADING" });
      try {
        const { data } = await axios.get(`${backendApiUrl}/items/get-item`);
        setItemsData(data);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "HIDE_LOADING" });
      }
    };
    getAllItems();
  }, [dispatch]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(`${backendApiUrl}/categories/get-categories`);
        setCategories([...hardcodedCategories, ...data]);
      } catch (error) {
        console.error(error);
      }
    };
    getCategories();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddCategory = async () => {
    try {
      const { data } = await axios.post(`${backendApiUrl}/categories/add-category`, newCategory);
      setCategories([...categories, data]);
      setNewCategory({ name: "", imageUrl: "" });
      setIsModalVisible(false);
      message.success("Category added successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to add category");
    }
  };

  const filteredItems = searchTerm.length > 2
    ? itemsData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : itemsData.filter(item => item.category === selectedCategory);

    const shouldShowCategories = searchTerm.length <= 2;
  return (
    <DefaultLayout>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            placeholder="Search item by name."
            onChange={handleSearch}
            value={searchTerm}
            style={{ width: 200, marginBottom: 20, marginRight: 20 }}
          />
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Category
          </Button>
          {shouldShowCategories && ( // Add this condition
          <div className="d-flex flex-wrap">
            {categories.map((category) => (
              <div
                key={category.name}
                className={`d-flex flex-column align-items-center category ${
                  selectedCategory === category.name && searchTerm.length === 0 ? "category-active" : ""
                }`}
                onClick={() => {
                  setSelectedCategory(category.name);
                  setSearchTerm('');
                }}
                style={{ marginRight: 10, marginBottom: 10, cursor: "pointer" }}
              >
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  height="50"
                  width="50"
                  loading="lazy"
                />
                <p>{category.name}</p>
              </div>
            ))}
          </div>
        )}
      </Col>
    </Row>
    <Row gutter={[16, 4]} justify="start">
      {filteredItems.map((item) => (
        <Col key={item.id} xs={24} sm={12} md={8} lg={4} className="itemColumn">
          <ItemList item={item} />
        </Col>
      ))}
    </Row>
      <Modal
        title="Add New Category"
        visible={isModalVisible}
        onOk={handleAddCategory}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Category Name" required>
            <Input
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Image URL">
            <Input
              value={newCategory.imageUrl}
              onChange={(e) => setNewCategory({ ...newCategory, imageUrl: e.target.value })}
            />
          </Form.Item>
          <ImageUpload onImageUpload={(url) => setNewCategory({ ...newCategory, imageUrl: url })} />
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default Homepage;
