import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, message } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import ImageUpload from "../components/ImageUpload";
import DefaultLayout from './../components/DefaultLayout';

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [newCategory, setNewCategory] = useState({ name: "", imageUrl: "" });
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;
  const dispatch = useDispatch();

  const hardcodedCategories = [
    { name: "All Weather", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255623/All-Weather-3_540x_gdibhz.png" },
    { name: "Plastic Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255568/Plastic-Emulsion-2_ab5dfa.png" },
    { name: "Super Emulsion", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255658/Brighto-Super-Emulsion_2048x2048_ppzyfd.png" },
    { name: "Synthetic Enamel", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255707/Synthatic-Enamel-2_nxyi4i.png" },
    { name: "Stain Free", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255739/Stain-Free-2_bzkwzg.png" },
    { name: "Velvet", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713255786/velvet-gallon_j28nqz.png" },
    { name: "Sanitary", imageUrl: "https://res.cloudinary.com/drgq2hmuq/image/upload/v1713256974/Sanitary_jpt9aa.jpg" },
  ];

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(`${backendApiUrl}/categories/get-categories`);
        setCategories([...hardcodedCategories, ...data]);
      } catch (error) {
        console.error(error);
      }
    };
    getCategories();
  }, [backendApiUrl]);

  const confirmDelete = (categoryId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this category?',
      onOk: () => handleDelete(categoryId),
    });
  };

  const handleDelete = async (categoryId) => {
    try {
      await axios.post(`${backendApiUrl}/categories/delete-category`, { categoryId });
      setCategories(categories.filter(category => category._id !== categoryId));
      message.success("Category deleted successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to delete category");
    }
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
    setNewCategory({ name: category.name, imageUrl: category.imageUrl });
    setIsModalVisible(true);
  };

  const handleAddOrUpdateCategory = async () => {
    try {
      if (editingCategory) {
        await axios.post(`${backendApiUrl}/categories/edit-category`, {
          categoryId: editingCategory._id,
          ...newCategory,
        });
        setCategories(categories.map(category => category._id === editingCategory._id ? newCategory : category));
        message.success("Category updated successfully");
      } else {
        const { data } = await axios.post(`${backendApiUrl}/categories/add-category`, newCategory);
        setCategories([...categories, data]);
        message.success("Category added successfully");
      }
      setNewCategory({ name: "", imageUrl: "" });
      setIsModalVisible(false);
      setEditingCategory(null);
    } catch (error) {
      console.error(error);
      message.error("Failed to add/update category");
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text) => <img src={text} alt="category" style={{ width: 50, height: 50 }} />,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" danger onClick={() => confirmDelete(record._id)}>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <Table columns={columns} dataSource={categories} rowKey="_id" />
      <Modal
        title={editingCategory ? "Edit Category" : "Add New Category"}
        visible={isModalVisible}
        onOk={handleAddOrUpdateCategory}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingCategory(null);
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Category Name" required>
            <Input
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Image URL">
            <Input
              value={newCategory.imageUrl}
              onChange={(e) => setNewCategory({ ...newCategory, imageUrl: e.target.value })}
            />
          </Form.Item>
          <ImageUpload onImageUpload={(url) => setNewCategory({ ...newCategory, imageUrl: url })} />
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default CategoriesList;