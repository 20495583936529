import React, { useEffect, useState } from 'react';
import { Input, message, Select, Collapse, Tabs } from 'antd';
import axios from 'axios';
import DefaultLayout from '../components/DefaultLayout';

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const StockDetails = () => {
  const [stockData, setStockData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [grandTotalStockAmount, setGrandTotalStockAmount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [lowStockItems, setLowStockItems] = useState([]);
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;

  useEffect(() => {
    fetchStockDetails();
    fetchLowStockItems();
  }, []);

  const fetchStockDetails = async () => {
    try {
      const { data } = await axios.get(`${backendApiUrl}/items/stock-details`);
      let total = 0;
      for (const category in data) {
        total += data[category].totalStockAmount;
      }
      setGrandTotalStockAmount(total);
      setStockData(data);
      setFilteredData(data);
    } catch (error) {
      message.error('Failed to fetch stock details');
    }
  };

  const fetchLowStockItems = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/items/low-stock`);
      setLowStockItems(response.data);
    } catch (error) {
      console.error('Failed to fetch low stock items:', error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = {};
    Object.keys(stockData).forEach(category => {
      const filteredItems = Object.values(stockData[category].items).filter(item => 
        item.name.toLowerCase().includes(value) || category.toLowerCase().includes(value)
      );
      if (filteredItems.length > 0) {
        filtered[category] = {
          ...stockData[category],
          items: filteredItems.reduce((acc, item) => {
            acc[item._id] = item;
            return acc;
          }, {})
        };
      }
    });

    setFilteredData(filtered);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const renderPackingOptions = (packingOptions) => {
    return packingOptions.map((opt, index) => (
      <div key={index}>
        {opt.packing}: Rs. {opt.price}, Stock: {opt.stock}, Avg Price: {opt.averagePrice ? Number(opt.averagePrice).toFixed(2) : 'N/A'}, Stock Amount: {opt.stockAmount ? Number(opt.stockAmount).toFixed(2) : 0}
      </div>
    ));
  };

  const renderItems = (items) => {
    return Object.values(items).map((item, index) => (
      <Panel header={item.name} key={index}>
        {renderPackingOptions(item.packingOptions)}
        <div><strong>Total Stock Amount for {item.name}: Rs. {item.totalStockAmount ? Number(item.totalStockAmount).toFixed(2) : 0}</strong></div>
      </Panel>
    ));
  };

  const renderCategories = () => {
    if (!selectedCategory) {
      return Object.keys(filteredData).map((category, index) => (
        <div key={index} style={{ marginBottom: 32 }}>
          <h2>{category}</h2>
          <Collapse>
            {renderItems(filteredData[category].items)}
          </Collapse>
          <div><strong>Total Stock Amount for {category}: Rs. {filteredData[category].totalStockAmount ? Number(filteredData[category].totalStockAmount).toFixed(2) : 0}</strong></div>
        </div>
      ));
    } else {
      const categoryData = filteredData[selectedCategory];
      if (!categoryData) return null;
      return (
        <div style={{ marginBottom: 32 }}>
          <h2>{selectedCategory}</h2>
          <Collapse>
            {renderItems(categoryData.items)}
          </Collapse>
          <div><strong>Total Stock Amount for {selectedCategory}: Rs. {categoryData.totalStockAmount ? Number(categoryData.totalStockAmount).toFixed(2) : 0}</strong></div>
        </div>
      );
    }
  };

  const renderLowStockItems = () => {
    return (
      <div>
        {lowStockItems.length > 0 ? (
          <div>
            {lowStockItems.map((item) => (
              <div key={item._id}>
                <h4>{item.name}</h4>
                <p>Category: {item.category}</p>
                <ul>
                  {item.lowStockPackingOptions.map((option) => (
                    <li key={option.packing}>
                      {option.packing} - Stock: {option.stock}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <p>No low stock items</p>
        )}
      </div>
    );
  };

  return (
    <DefaultLayout>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Stock" key="1">
          <div className="stock-details-header">
            <h1>Stock Details</h1>
            <Select
              placeholder="Select Category"
              onChange={handleCategoryChange}
              style={{ width: 200, marginBottom: 16 }}
            >
              {Object.keys(stockData).map((category, index) => (
                <Option key={index} value={category}>{category}</Option>
              ))}
            </Select>
            <Input
              placeholder="Search items by name"
              value={searchText}
              onChange={handleSearch}
              style={{ width: 300, marginBottom: 16 }}
            />
          </div>
          <div>
            {renderCategories()}
            <div><strong>Grand Total of All Categories: Rs. {grandTotalStockAmount ? Number(grandTotalStockAmount).toFixed(2) : 0}</strong></div>
          </div>
        </TabPane>
        <TabPane tab="Low Stock Items" key="2">
          {renderLowStockItems()}
        </TabPane>
      </Tabs>
    </DefaultLayout>
  );
};

export default StockDetails;
