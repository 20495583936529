import React, { useState, useEffect } from "react";
import { Button, Card, Select } from "antd";
import { useDispatch } from "react-redux";
import "./ItemList.css";

const ItemList = ({ item }) => {
  const dispatch = useDispatch();

  const getInitialPacking = () => {
    const defaultPacking = item.packingOptions.find(p => p.packing === 'quarter') || item.packingOptions[0];
    return defaultPacking;
  };

  const [selectedPacking, setSelectedPacking] = useState(getInitialPacking);

  useEffect(() => {
    setSelectedPacking(getInitialPacking());
  }, [item]);

  const handleAddToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        ...item,
        quantity: 1,
        selectedPacking: selectedPacking.packing,
        price: selectedPacking.latestPrice,
        stock: selectedPacking.stock
      },
    });
  };

  const { Meta } = Card;
  const { Option } = Select;

  return (
    <Card
      style={{ width: 150, marginBottom: 10}}
      cover={<img alt={item.name} src={item.image || './defaultProductImage.jpg'} style={{ height: 100 }} />}
    >
      <Meta title={item.name} description={`${item.category}`} className="metaInfo"/>
      <p style={{color: 'red'}}>{selectedPacking.packing}: Rs.{selectedPacking.latestPrice} (Stock: {selectedPacking.stock})</p>
      <Select
        value={selectedPacking.packing}
        style={{ width: '100%', marginBottom: 5 }}
        onChange={value => {
          const packing = item.packingOptions.find(p => p.packing === value);
          setSelectedPacking(packing);
        }}
      >
        {item.packingOptions.map(opt => (
          <Option key={opt._id} value={opt.packing}>{opt.packing}</Option>
        ))}
      </Select>
      <Button onClick={handleAddToCart} type="primary" block>Add to cart</Button>
    </Card>
  );
};

export default ItemList;
