import { EyeOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Table, Input, message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import DefaultLayout from "../components/DefaultLayout";
import "../styles/InvoiceStyles.css";
import "./BillsPage.css";
import { debounce } from 'lodash';


const BillsPage = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [billsData, setBillsData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;
  const [searchText, setSearchText] = useState("");
  const [newlyGeneratedBill, setNewlyGeneratedBill] = useState(null);
  const [returnModalVisible, setReturnModalVisible] = useState(false);
  const [returnDetails, setReturnDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBills, setTotalBills] = useState(0);
  const [returnedItems, setReturnedItems] = useState([]);

  const debouncedGetAllBills = useCallback(debounce((page, search) => {
    getAllBills(page, search);
  }, 1000), []);

  const isNewlyGenerated = (createdAt) => {
    const tenSecondsAgo = new Date(Date.now() - 10000);
    return new Date(createdAt) > tenSecondsAgo;
  };

  const initiateReturn = (record) => {
    setSelectedBill(record);
    setReturnModalVisible(true);
  };

  const calculateTotalDiscount = (cartItems) => {
    return cartItems.reduce(
      (total, item) => total + (item.discountAmount || 0),
      0
    );
  };

  const getAllBills = async (page = 1, search = "") => {
    try {
      dispatch({
        type: "SHOW_LOADING",
      });
      const { data } = await axios.get(`${backendApiUrl}/bills/get-bills`, {
        params: { page, limit: 10, search }
      });
      const sortedData = data.bills.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const newlyGeneratedBill = sortedData.find((bill) =>
        isNewlyGenerated(bill.createdAt)
      );
      const updatedData = sortedData.map((bill) => ({
        ...bill,
        totalDiscount: calculateTotalDiscount(bill.cartItems),
      }));
      setBillsData(updatedData);
      setTotalBills(data.total);
      setCurrentPage(page);
      setNewlyGeneratedBill(newlyGeneratedBill);
      dispatch({ type: "HIDE_LOADING" });
      console.log(data);
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      console.log(error);
    }
  };

  useEffect(() => {
    if (newlyGeneratedBill) {
      setSelectedBill(newlyGeneratedBill);
      setPopupModal(true);
    }
  }, [newlyGeneratedBill]);

  useEffect(() => {
    getAllBills();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedGetAllBills(1, value); 
  };


  const fetchReturnedItems = async (billId) => {
    try {
      const { data } = await axios.get(`${backendApiUrl}/bills/returns/${billId}`);
      setReturnedItems(data);
    } catch (error) {
      console.error("Error fetching returned items:", error);
    }
  };

  useEffect(() => {
    return () => {
      debouncedGetAllBills.cancel();
    };
  }, [debouncedGetAllBills]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    getAllBills(pagination.current);
  };

  const columns = [
    { title: "Invoice Number", dataIndex: "invoiceNumber" },
    {
      title: "Date / Time",
      dataIndex: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    { title: "Customer Name", dataIndex: "customerName" },
    {
      title: "Subtotal",
      dataIndex: "subTotal",
      render: (subTotal) => subTotal.toFixed(2),
    },
    {
      title: "Discount",
      dataIndex: "totalDiscount",
      render: (totalDiscount) => totalDiscount.toFixed(2),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      render: (totalAmount, record) => {
        const netAmount = totalAmount - (record.returnedAmount || 0);
            if (netAmount < 0) {
          return "0.00";
        }
    
        return netAmount.toFixed(2);
      },
    },
    
    
    {
      title: "View",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedBill(record);
              fetchReturnedItems(record._id);
              setPopupModal(true);
            }}
          />
        </div>
      ),
      responsive: ["md"],
    },
    {
      title: "Return",
      dataIndex: "_id",
      render: (id, record) => (
          <Button
            danger
            onClick={() => initiateReturn(record)}
            disabled={record.isReturned}
          >
            Return
          </Button>
      ),
      responsive: ["md"],
    },
  ];

  const updateReturnDetails = (item, quantity) => {
    const { _id, totalAfterDiscount, quantity: itemQuantity, selectedPacking } = item; 
    const pricePerItem = totalAfterDiscount / itemQuantity;
    const updatedReturns = { 
        ...returnDetails, 
        [_id]: { quantity, price: pricePerItem, selectedPacking }
    };
    setReturnDetails(updatedReturns);
};

  const handleReturnSubmit = async () => {
    const returnedItems = Object.entries(returnDetails).map(
      ([itemId, details]) => ({
        itemId,
        quantity: details.quantity,
        price: details.price,
        name: details.name,
        selectedPacking: details.selectedPacking
      })
    );

      // Add this validation
  for (const returnedItem of returnedItems) {
    const originalItem = selectedBill.cartItems.find(item => item._id === returnedItem.itemId);
    if (!originalItem || returnedItem.quantity > originalItem.quantity) {
      message.error(`Cannot return more than the original quantity for an item`);
      return;
    }
  }

    const totalRefundAmount = returnedItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );

    try {
      await axios.post(`${backendApiUrl}/bills/returns`, {
        billId: selectedBill._id,
        customerName: selectedBill.customerName,
        customerNumber: selectedBill.customerNumber,
        returnedItems,
        totalRefundAmount,
      });
      message.success("Return processed successfully");
      setReturnModalVisible(false);
      getAllBills();
    } catch (error) {
      message.error("Failed to process return");
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Invoice List</h1>
      </div>
      <Input
        placeholder="Search by Invoice No. or Customer Name"
        value={searchText}
        onChange={handleSearch}
        style={{ width: 300, marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={billsData}
        bordered
        scroll={{ x: "max-content" }}
        pagination={{
          current: currentPage,
          total: totalBills,
          pageSize: 10,
          onChange: (page) => getAllBills(page, searchText)
        }}
        onChange={handleTableChange}
      />
      {popupModal && (
        <Modal
          width={400}
          pagination={false}
          title="Invoice Details"
          open={popupModal}
          onCancel={() => {
            setPopupModal(false);
          }}
          footer={false}
        >
          {/* ============ invoice modal start ==============  */}
          <div id="invoice-POS" ref={componentRef}>
            <center id="top">
              <div className="info">
                <h6>
                  <b>Green Home Solutions Pakistan</b>
                </h6>
                <p>
                  {" "}
                  Hajij Ilyas Plaza, Gate# 1 <br />
                  Sundar Industrial Estate, Lahore <br />
                  Contact : +923334211173
                </p>
              </div>
            </center>

            <div id="mid">
              <div className="mt-2">
                <p>
                  Invoice Number: <b>{selectedBill.invoiceNumber}</b>
                  <br />
                  Customer Name: <b>{selectedBill.customerName}</b>
                  <br />
                  Phone No: <b>{selectedBill.customerNumber}</b>
                  <br />
                  Date:{" "}
                  <b>{new Date(selectedBill.createdAt).toLocaleString()}</b>
                  <br />
                </p>
                <hr style={{ margin: "5px" }} />
              </div>
            </div>

            <div id="bot">
              <div id="table">
                <table>
                  <tbody>
                    <tr className="tabletitle">
                      <td className="item table-header">
                        <p>
                          <b>Item</b>
                        </p>
                      </td>
                      <td className="Hours table-header">
                        <p>
                          <b>Qty</b>
                        </p>
                      </td>
                      <td className="Rate table-header">
                        <p>
                          <b>Price</b>
                        </p>
                      </td>
                      <td className="Rate table-header">
                        <p>
                          <b>Dis.%</b>
                        </p>
                      </td>
                      <td className="Rate table-header">
                        <p>
                          <b>Dis.</b>
                        </p>
                      </td>
                      <td className="Rate table-header">
                        <p>
                          <b>Total</b>
                        </p>
                      </td>
                    </tr>
                    {selectedBill.cartItems.map((item) => (
                      <tr className="service" key={item._id}>
                        <td className="tableitem">
                          <p className="itemtext">
                            {item.category}
                            <br></br>
                            {item.name}
                          </p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">{item.quantity}</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">{item.price}</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">{item.discount}%</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            {item.discountAmount.toFixed(2)}
                          </p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            {item.totalAfterDiscount.toFixed(2)}
                          </p>
                        </td>
                      </tr>
                    ))}
 
                    <tr className="tabletitle">
                      <td />
                      <td />
                      <td />
                    </tr>
                    <tr className="tabletitle">
                      <td colSpan="5" className="Rate table-header"><p><b>Total</b></p></td>
                     
                      <td className="grand-total">
                        <p className="grand-total-value">
                          <b>{selectedBill.totalAmount.toFixed(2)}</b>
                        </p>
                      </td>
                    </tr>
                    {returnedItems.length > 0 && (
      <>
        <tr className="tabletitle">
          <td colSpan="6" className="Rate table-header">
            <p><b>Returned Items</b></p>
          </td>
        </tr>
        {returnedItems.map((returnItem) => (
          returnItem.returnedItems.map((item) => (
            <tr className="service" key={item.itemId}>
              <td className="tableitem">
                <p className="itemtext">{item.name || "Unknown Item"}</p>
              </td>
              <td className="tableitem">
                <p className="itemtext">{item.quantity}</p>
              </td>
              <td className="tableitem">
                <p className="itemtext">{item.price}</p>
              </td>
              <td className="tableitem">
                <p className="itemtext">-</p>
              </td>
              <td className="tableitem">
                <p className="itemtext">-</p>
              </td>
              <td className="tableitem">
                <p className="itemtext">{(item.quantity * item.price).toFixed(2)}</p>
              </td>
            </tr>
          ))
        ))}
      </>
    )}
                    {selectedBill.isReturned && selectedBill.returnedAmount > 0 && (
  <>
    {/* New row for Returned Amount */}
    <tr className="tabletitle">
      <td colSpan="5" className="Rate table-header"><p><b>Returned Amount</b></p></td>
      <td className="tableitem"><p className="itemtext">-{selectedBill.returnedAmount.toFixed(2)}</p></td>
    </tr>
    {/* New row for New Total */}
    <tr className="tabletitle">
      <td colSpan="5" className="Rate table-header"><p><b>New Total</b></p></td>
      <td className="tableitem"><p className="itemtext">{(selectedBill.totalAmount - selectedBill.returnedAmount).toFixed(2)}</p></td>
    </tr>
  </>
)}
                  </tbody>
                </table>
              </div>
              <div id="legalcopy">
                <p className="legal">
                  <strong>Thank you for your shopping!</strong>
                  <br></br> Discount is applicable on retail price before Tax.
                  <br></br>
                  <b> Email: dilshadrana729@gmail.com</b>
                </p>
              </div>
            </div>
          </div>

          {/*End Invoice*/}
          <div className="d-flex justify-content-end mt-3">
            <Button type="primary" onClick={handlePrint}>
              Print
            </Button>
          </div>
          {/* ============ invoice modal ends ==============  */}
        </Modal>
      )}
      <Modal
        title="Process Return"
        visible={returnModalVisible}
        onCancel={() => setReturnModalVisible(false)}
        footer={null}
        width={800}
      >
        <Form onFinish={handleReturnSubmit}>
        {selectedBill && selectedBill.cartItems && selectedBill.cartItems.map((item) => (
    <Form.Item key={item._id} label={`${item.name} - ${item.selectedPacking}`}>
        <InputNumber
            min={0}
            max={item.quantity}
            defaultValue={0}
            onChange={(value) => updateReturnDetails(item, value)}
        />
    </Form.Item>
))}

          <Form.Item>
            <Button type="primary" htmlType="submit"
            disabled={!selectedBill || selectedBill.cartItems.length === 0}
            >
              Submit Return
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default BillsPage;