import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import "./CartPage.css";

const CartPage = () => {
  const [subTotal, setSubTotal] = useState(0);
  const [billPopup, setBillPopup] = useState(false);
  const [discounts, setDiscounts] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.rootReducer);
  const backendApiUrl = process.env.REACT_APP_BACKEND_API;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleIncrement = (record) => {
    dispatch({
      type: "UPDATE_CART",
      payload: {
        _id: record._id,
        selectedPacking: record.selectedPacking,
        quantity: record.quantity + 1,
      },
    });
  };

  const handleDecrement = (record) => {
    if (record.quantity > 1) {
      dispatch({
        type: "UPDATE_CART",
        payload: {
          _id: record._id,
          selectedPacking: record.selectedPacking,
          quantity: record.quantity - 1,
        },
      });
    }
  };

  const handleDiscountChange = (id, value) => {
    setDiscounts({ ...discounts, [id]: parseFloat(value) || 0 });
  };

  const columns = [
    { title: "Name", dataIndex: "name" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img src={image} alt={record.name} height="60" width="60" />
      ),
    },
    { title: "Price", dataIndex: "price" },
    {
      title: "Quantity",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <PlusCircleOutlined
            className="mx-3"
            style={{ cursor: "pointer" }}
            onClick={() => handleIncrement(record)}
          />
          <b>{record.quantity}</b>
          <MinusCircleOutlined
            className="mx-3"
            style={{ cursor: "pointer" }}
            onClick={() => handleDecrement(record)}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <DeleteOutlined
          style={{ cursor: "pointer" }}
          onClick={() =>
            dispatch({
              type: "DELETE_FROM_CART",
              payload: record,
            })
          }
        />
      ),
    },
  ];

  useEffect(() => {
    let temp = 0;
    cartItems.forEach((item) => {
      const itemTotal = item.price * item.quantity;
      temp += itemTotal;
    });
    setSubTotal(temp.toFixed(2));
  }, [cartItems]);

  const isStockAvailable = async () => {
    for (const item of cartItems) {
      const response = await axios.post(`${backendApiUrl}/items/check-stock`, {
        itemId: item._id,
        quantity: item.quantity,
        packing: item.selectedPacking,
      });
      if (response.data.status === 'insufficient_stock') {
        message.error(`Insufficient stock for item ${item.name}`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (value) => {
    if (!(await isStockAvailable())) {
      message.error('Unable to generate bill due to insufficient stock.');
      return;
    }
    setIsSubmitting(true);
    try {    
        const cartItemsWithDiscounts = cartItems.map((item) => {
          const discount = discounts[item._id] || 0;
          const itemTotal = item.price * item.quantity;
          const discountAmount = (itemTotal * discount) / 100;
          const totalAfterDiscount = itemTotal - discountAmount;
      
          return {
            ...item,
            discount,
            discountAmount,
            totalAfterDiscount,
          };
        });
      
        const newObject = {
          ...value,
          cartItems: cartItemsWithDiscounts,
          subTotal,
          totalDiscount: cartItemsWithDiscounts.reduce(
            (acc, item) => acc + item.discountAmount,
            0
          ),
          totalAmount: cartItemsWithDiscounts.reduce(
            (acc, item) => acc + item.totalAfterDiscount,
            0
          ),
          userId: JSON.parse(localStorage.getItem("user"))._id,
        };
      
      const response = await axios.post(
        `${backendApiUrl}/bills/add-bills`,
        newObject
      );

      if (response.status === 200) {
        cartItems.forEach(async (item) => {
          await axios.post(`${backendApiUrl}/items/decrement-stock`, {
            itemId: item._id,
            quantity: item.quantity,
            packing: item.selectedPacking,
          });
        });

        message.success("Bill Generated");
        navigate("/bills");
        dispatch({ type: "CLEAR_CART" });
      }
    } catch (error) {
      message.error("Something went wrong");
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DefaultLayout>
      <h1>Cart Page</h1>
      <Table
        columns={columns}
        dataSource={cartItems}
        bordered
        scroll={{ x: "max-content" }}
      />
      <div className="d-flex flex-column align-items-end">
        <hr />
        <h3>
          SUB TOTAL : PKR <b>{subTotal}</b> /-{" "}
        </h3>
        <Button type="primary" onClick={() => setBillPopup(true)}>
          Create Invoice
        </Button>
      </div>
      <Modal
        title="Create Invoice"
        open={billPopup}
        onCancel={() => setBillPopup(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Please enter customer name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="customerNumber" label="Contact Number">
            <Input />
          </Form.Item>

          <Form.Item name="paymentMode" label="Payment Method">
            <Select>
              <Select.Option value="cash">Cash</Select.Option>
              <Select.Option value="card">Card</Select.Option>
            </Select>
          </Form.Item>

          {cartItems.map((item) => (
            <div key={item._id} className="bill-item">
              <h5>
                {item.name} (Price: {item.price} x {item.quantity} ={" "}
                {item.price * item.quantity})
              </h5>
              <Form.Item label={`Discount for ${item.name} (%)`}>
                <Input
                  type="number"
                  step="0.1"
                  min="0"
                  max="100"
                  onChange={(e) => handleDiscountChange(item._id, e.target.value)}
                />
              </Form.Item>
            </div>
          ))}

          <div className="bill-it">
            <h5>
              Sub Total : <b>{subTotal}</b>
            </h5>
            <h5>
              Total Discount : <b>{cartItems.reduce((acc, item) => acc + item.price * item.quantity * (discounts[item._id] || 0) / 100, 0).toFixed(2)}</b>
            </h5>
            <h5>
              GRAND TOTAL :{" "}
              <b>
                {(
                  subTotal -
                  cartItems.reduce(
                    (acc, item) =>
                      acc + item.price * item.quantity * (discounts[item._id] || 0) / 100,
                    0
                  )
                ).toFixed(2)}
              </b>
            </h5>
          </div>
          <div className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          Generate Bill
            </Button>
          </div>
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default CartPage;
