import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';

const ImageUpload = ({ onImageUpload }) => {
  const [widget, setWidget] = useState(null);

  const setupWidget = useCallback(() => {
    if (!widget) {
      const uploadWidget = window.cloudinary.createUploadWidget({
        cloudName: 'drgq2hmuq',
        uploadPreset: 'p4decmid',
        sources: ['local', 'url', 'camera'],
        clientAllowedFormats: ["jpg", "png", "gif"],
        maxFileSize: 200000,
        maxImageWidth: 2000,
        maxImageHeight: 2000,
        cropping: false,
      }, (error, result) => {
        if (error) {
          console.error('Upload Error:', error);
          return;
        }
        if (result.event === 'success') {
          console.log('Image URL:', result.info.secure_url);
          onImageUpload(result.info.secure_url);
        }
      });

      setWidget(uploadWidget);
    }
  }, [widget, onImageUpload]);

  useEffect(() => {
    setupWidget();
  }, [setupWidget]);

  const showWidget = () => {
    if (widget) {
      widget.open();
    } else {
      console.error("Cloudinary widget is not initialized");
    }
  };

  return <Button onClick={showWidget}>Upload Image</Button>;
};

export default ImageUpload;
