const initialState = {
  loading: false,
  cartItems: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_LOADING":
      return { ...state, loading: true };
    case "HIDE_LOADING":
      return { ...state, loading: false };
    case "ADD_TO_CART":
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        item => item._id === newItem._id && item.selectedPacking === newItem.selectedPacking
      );

      if (existingItem) {
        // Increment the quantity of an existing item
        return {
          ...state,
          cartItems: state.cartItems.map(item =>
            item._id === newItem._id && item.selectedPacking === newItem.selectedPacking ?
            { ...item, quantity: item.quantity + 1 } : item
          )
        };
      } else {
        // Add a new item if it doesn't exist
        return {
          ...state,
          cartItems: [...state.cartItems, { ...newItem, quantity: 1 }] // Ensure new items start with quantity 1
        };
      }
    case "UPDATE_CART":
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item._id === action.payload._id && item.selectedPacking === action.payload.selectedPacking ?
          { ...item, quantity: action.payload.quantity } : item
        )
      };
    case "DELETE_FROM_CART":
      return {
        ...state,
        cartItems: state.cartItems.filter(item =>
          !(item._id === action.payload._id && item.selectedPacking === action.payload.selectedPacking)
        )
      };
    case "CLEAR_CART":
      return { ...state, cartItems: [] };
    default:
      return state;
  }
};

export default rootReducer;
